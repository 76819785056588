:root {
  --page-border-color: #20c7d4;
}

html {
  height: 100%;
  margin: 2px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 22px;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: url("/password.ttf");
}

.main-logo {

}

a {
  color: #000;
}

.center {
  text-align: center;
}

.hidden {
  display: none !important;
}

.fit-content-center {
  margin: 0 auto;
  width: fit-content;
}

.form .hidden {
  display: none !important;
}

#root {
  height: calc(100% - 34px); /* 15 + 15 from border, 2 + 2 from html margin */
  border: 15px solid var(--page-border-color, #20c7d4);
  background-color: var(--page-border-color, #20c7d4);
  border-radius: 20px;
}

.container {
  height: 100%;
  width: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
  display: none;
}

.full-background {
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  border-radius: 10px;
}

input[type="submit"] {
  -webkit-appearance: none;
  background-color: #efefef;
  color: #000;
}

.error-page {
  border-radius: 15px;
  padding: 20px;
  vertical-align: top;
  min-width: 200px;
  background-color: #ffffffe8;
  margin: 20px;
}

.error-page h2 {
  margin-top: 0px;
}

.logo-container {
  display: block;
  max-height: 8vw;
  max-width: 60vw;
  height: 100%;
  width: 100%;
  margin: auto;
  margin-bottom: 10px;
}

.logo-container.big {
  max-height: 12vw;
  max-width: 80vw;
  margin-bottom: 10px;
}

.logo {
  height: 8vw;
  width: 100%;
  object-fit: contain;
}

.big .logo {
  height: min(9vw, 15vh);
}

a:has(> .logo-container) {
  display: block;
  min-height: 80px;
  line-height: 8px;
}

.header {
  font-weight: bold;
  border-radius: 11px;
  padding: 20px 50px;
  padding-left: 50px;
  vertical-align: top;
  min-width: 200px;
  background-color: #ffffffde;
  margin-top: 10px;
  box-shadow: 0px 0px 60px 0px #ffffffbd;
}

.header.big {
  font-size: max(22px, min(2.5vw, 5vh));
  font-weight: bold;
  border-radius: 11px;
  padding: 20px 50px;
  padding-left: 50px;
  vertical-align: top;
  min-width: 200px;
  background-color: #ffffffde;
  margin-top: 10px;
  box-shadow: 0px 0px 60px 0px #ffffffbd;
}

.header a {
  text-decoration: none;
}

/* -- login page -- */

.center-container {
  display: block;
  text-align: center;
}

.login-box {
  display: inline-block;
  text-align: left;
}

.welcome-to {
  margin-bottom: 1px;
  margin-left: 71px;
}

.main-logo {
    width: 750px;
}

.login-form {
  margin: 30px auto 0;

  border-radius: 15px;
  padding: 20px;
  max-width: 800px;
  background-color: #ffffffde;
}

.login-form h3 {
  margin-top: 10px;
}

.login-form input:not([type="submit"]) {
  width: 98%;
  height: 30px;
  font-size: 22px;
  padding-left: 6px;
  margin-bottom: 10px;
  border: 1px solid #aaa;
  border-radius: 5px;
}

.login-form select {
  width: 100%;
  height: 30px;
  font-size: 22px;
  padding-left: 6px;
  margin-bottom: 30px;
  border: 1px solid #aaa;
  border-radius: 5px;
  cursor: pointer;

  -webkit-appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='70' fill='000000'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-size: 12px;
  background-position: calc(100% - 12px) center;
  background-repeat: no-repeat;
  background-color: #fff;
}

.login-form p {
  margin: 0 0 30px;
}

.login-form input[type="submit"] {
  padding: 15px 7px;
  background-color: #ffffffde;
  border: 7px solid var(--page-border-color, #20c7d4);
  border-radius: 30px;
  min-width: 200px;
  font-size: 22px;
  display: block;
  margin: auto;
}

.login-form input[type="submit"]:hover {
  cursor: pointer;
  background-color: var(--page-border-color, #20c7d4);
  color: #000;
  font-weight: 600;
  border: 7px solid #000;
}

.third-party-login-container {
  margin: 20px auto;
  width: min-content;
  cursor: pointer;
}

.errors {
  margin-top: 20px;
  background-color: lightpink;
  border: 3px solid #ff6a6a;
  border-radius: 15px;
  padding: 15px 0 0 15px;
}


.logout-message {
  text-align: center;
  margin-top: 60px;
}


/* -- homepage -- */

.homepage {
  display: flex;
  min-height: 100%;
  min-width: 100%;
  justify-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-decoration: none;
}

.homepage div {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.homepage .header {
  grid-area: header;
  padding: 30px;
}

.homepage .blank {
  grid-area: blank;
}

.homepage .footer {
  grid-area: footer;
  padding: 30px;
}

.qr-placeholder {
  color: #fff;
  margin-bottom: 20px;
  background-color: #999;
  margin: auto;
  text-align: center;
  border-radius: 5px;
}

.qr-placeholder.small {
  height: 96px;
  width: 128px;
  line-height: 32px;
  padding-top: 32px;
}

.qr-placeholder.default {
  font-weight: 600;
  height: 256px;
  width: 256px;
  line-height: 256px;
}

.single-qr-container {
  margin-top: 30px;
  background-color: #ffffffde;
  padding: 10px;
  border-radius: 15px;
}

.qr-code {
  margin: auto;
  margin-top: 10px;
  width: fit-content;
}

.message {
  white-space: break-spaces;
}

.show-on-mobile {
  display: none !important;
}

.hide-on-mobile {
  display: block;
  margin-top: 0px !important;
}

.scroll-message {
}

.emergency-button {
  display: block;
  border: 3px solid var(--page-border-color, #20c7d4);
  border-radius: 15px;
  padding: 20px;
  vertical-align: top;
  background-color: #ffffffde;
  text-align: center;
  width: fit-content;
  margin: 10px;
  text-decoration: none;
  position: absolute;
  right: 20px;
  z-index: 20;
}

a.emergency-button:hover {
  text-decoration: underline;
  border-color: #000;
}

.password-font {
  font-family: "password";
  -webkit-text-security: disc;
}

.status-bar {
  position: absolute;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  bottom: 30px;
}

.status-textbox {
  text-align: center;

  border-radius: 15px;
  padding: 20px;
  vertical-align: top;
  background-color: #ffffff8f;
  margin-top: 20px;
  width: max-content;
  z-index: 1;
}

.status-textbox.left {
  margin-left: 20px;
  margin-right: auto;
}

.status-textbox.right {
  margin-left: auto;
  margin-right: 50px;
}

.circle {
  border-radius: 20px;
  display: inline-block;
  min-width: 8px;
  line-height: 11px;
  min-height: 8px;
  padding: 5px 5px 5px 5px !important;
  margin-right: 10px;
}

.circle.red {
  margin-left: -15px;
  background-color: red;
  box-shadow: 0px 0px 8px 1px red;
}

.maximum-urgency .circle.red {
  animation: blinker 3s linear infinite;
}

.circle.yellow {
  background-color: yellow;
  box-shadow: 0px 0px 8px 1px yellow;
}

.circle.green {
  background-color: #00ff00;
  box-shadow: 0px 0px 8px 1px #00ff00;
}

.maximum-urgency {
  margin-right: -10px;
  font-weight: bold;
  font-size: 20px;
  color: red;
}

.high-urgency {
  margin-right: -10px;
  font-weight: high;
  color: red;
}

.low-urgency {
  margin-right: -10px;
  color: orange;
}

.minimal-urgency {
  margin-right: -10px;
}

@keyframes blinker {
  50% {
    background-color: orange;
    box-shadow: 0px 0px 8px 1px yellow;
  }
}

.modal-container {
  position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  height: 100%;
  border-radius: 10px;
  z-index: 30;
}

.modal-content {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 30px 60px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 60px 0px #000000bd;
  max-height: 100%;
  overflow: auto;
}

.modal-content.manual-check-out {
  min-width: 690px;
}

.modal-title {
  margin: auto;
  width: fit-content;
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 20px;
}

.center-two-buttons {
  width: 376px;
  margin: auto;
}

.modal-button {
  height: 50px;
  width: 168px;
  font-size: 20px;
  margin: 30px 10px 0px;
  border-radius: 5px;
}

.modal-button:not([disabled]):hover {
  cursor: pointer;
  background-color: #20c7d4;
  color: #000;
  font-weight: 600;
  border: 2px solid #000;
}

.big-bottom-margin {
  margin-bottom: 100px;
}

/* -- profile page -- */

.main-page {
  display: flex;
  flex-direction: column;
  grid-template-areas: "header"
                       "content";
  grid-template-rows: auto 1fr;
  justify-items: center;
  text-decoration: none;
  justify-content: space-between;
  gap: 15px;
  position: relative;
  min-height: min(60vw, 800px);
}

.main-page.homepage {
  min-height: 80vh;
}

.main-page div {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
}
.main-page a {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
}

.main-page.back-button {
  grid-template-areas: "header"
                       "content"
                       "back";
  grid-template-rows: auto 1fr 2fr;
}

.main-page.two-back-buttons {
  grid-template-areas: "header"
                       "top-back"
                       "content"
                       "bottom-back";
  grid-template-rows: auto auto auto auto;
}

.main-page .header {
  grid-area: header;
}

.main-page .content {
  grid-area: content;
  text-align: center;
}

.main-page .back {
  grid-area: back;
  text-align: center;
  display: grid;
}

.back .widget {
  min-width: revert !important;
}

.main-page .top-back {
  grid-area: top-back;
  margin: 30px auto;
  text-align: center;
}

.main-page .bottom-back {
  grid-area: bottom-back;
  margin-top: 30px;
  margin-bottom: 60px;
  text-align: center;
}

.main-page .background {
  position: relative;
  border-radius: 15px;
  padding: 20px;
  vertical-align: top;
  min-width: 200px;
  background-color: #ffffffe8;
}

.back-arrow {
  position: absolute;
  top: 10px;
  left: 15px;
  font-size: 25px;
}

.widgets {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 20px;
  margin-top: 20px;
  padding: 0 20px;
}

.widgets.vertical {
  display: flex;
  flex-direction: column;
  min-width: 50vw;
}

.widget {
  border: 7px solid var(--page-border-color, #20c7d4);
  border-radius: 30px;
  padding: 20px;
  vertical-align: top;
  min-width: 200px;
  background-color: #ffffffde;
  text-align: center;
  text-decoration: none;
}

.widget.arriving-or-leaving {
  padding: 20px 30px;
  font-size: 30px;
}

button.widget {
  font-size: 22px;
  text-decoration: underline;
}

a.widget:hover,
button.widget:hover {
  background-color: var(--page-border-color, #20c7d4);
  border: 7px solid #000;
  cursor: pointer;
  text-decoration: underline;
}

.widget.fire-log {
  font-size: 20px;
}

.fire-log-row {
  margin: 0 0 10px !important;
}

.fire-log-row input {
  height: 30px;
  width: 30px;
  margin-right: 5px;
  vertical-align: middle;
}

.small {
  min-width: 100px !important;
  margin: auto;
}

.device-name {
  width: 100%;
  text-align: right;
  margin-right: 30px;
  margin-bottom: 10px;
}

.profile-with-qr {
  background-color: #ffffffe8;
  display: grid;
  padding: 8px;
  border-radius: 30px;
  grid-gap: 5px;
  z-index: 10;
}

.profile-without-qr {
  display: grid;
  padding: 3px;
}


.form {
  min-width: 40vw;
  margin: 0 auto;
  border-radius: 15px;
  padding: 20px 60px;
  max-width: 800px;
  position: relative;
  background-color: #ffffffde !important;
  border-color: #ffffffde !important;
}

.form.invisible {
  background-color: unset !important;
}

.form .message {
  margin-bottom: 25px;
}

.form .red {
  color: #b80c09;
  font-weight: 600;
}

.form .text {
  width: 100%;
  text-align: left;
}

.form label {
  display: grid;
}

.form h3 {
  margin-top: 10px;
}

.form input:not([type="submit"]) {
  width: 98%;
  height: 30px;
  font-size: 22px;
  padding-left: 6px;
  border: 1px solid #555;
  border-radius: 10px;
}

.form select {
  width: 100%;
  height: 30px;
  font-size: 22px;
  padding-left: 6px;
  margin-bottom: 30px;
  border: 1px solid #aaa;
  border-radius: 5px;
  cursor: pointer;

  -webkit-appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='70' fill='000000'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-size: 12px;
  background-position: calc(100% - 12px) center;
  background-repeat: no-repeat;
}

.form p {
  margin: 0 0 30px;
}

.form input[type="submit"] {
  padding: 15px 7px;
  background-color: #ffffffde;
  border: 7px solid var(--page-border-color, #20c7d4);
  border-radius: 30px;
  min-width: 200px;
  font-size: 22px;
}

.form input[type="submit"][disabled] {
  padding: 15px 15px;
  background-color: #ffffffde;
  border: 7px solid #ddd;
  color: #999;
  border-radius: 30px;
  min-width: 200px;
  font-size: 22px;
}

.form textarea {
  width: 97%;
  height: 150px;
  font-size: 22px;
  padding-left: 6px;
  margin-bottom: 10px;
  border: 1px solid rgb(170, 170, 170);
  border-radius: 5px;
  margin-top: 0px;
  font-family: inherit;
  outline: none;
  margin-top: 15px;
}

.form input[type="submit"]:not([disabled]):hover {
  cursor: pointer;
  background-color: var(--page-border-color, #20c7d4);
  color: #000;
  font-weight: 600;
  border: 7px solid #000;
}

.error {
  background-color: lightpink;
  border: 2px solid #ff6a6a;
  border-radius: 6px;
  padding: 5px 0px;
  width: 99% !important;
  margin-top: 5px;
}

/* -- arriving/leaving page -- */

.arriving-page .content, .leaving-page .content {
  width: 100%;
}

/* -- feedback page -- */

.feedback-page .feedback-type {

  border-radius: 15px;
  padding: 20px;
  vertical-align: top;
  min-width: 200px;
  background-color: #ffffffe8;
  margin-bottom: 30px;
}


.feedback-page .feedback-title {
  margin-bottom: 30px;
}

.feedback-page input[type="checkbox"] {
  width: 0;
  opacity: 0;
  padding: 0;
  margin: 0;
}

.feedback-page .radio label {
  border: 3px solid var(--page-border-color, #20c7d4);
  border-radius: 30px;
  padding: 20px;
  vertical-align: top;
  display: inline-block;
  cursor: pointer;
  min-width: 50px;
  margin: 10px;
  background-color: #ffffffde;
}

.feedback-page .radio label.longer {
  width: 130px;
}

.feedback-page input:checked + label {
  background-color: var(--page-border-color, #20c7d4);
  border-color: #000;
  text-decoration: underline;
}

.feedback-page .radio .mobile-stars label {
  border: none;
  border-radius: 0;
  padding: 10px;
  min-width: 0;
  margin: 0;
  background-color: transparent;
  font-size: 60px;
}

.feedback-page .radio .no:checked + label {
  background-color: #b80c09;
  border-color: #000;
  color: #ffffff;
}

.feedback-page .radio .yes:checked + label {
  background-color: #22ee80;
  border-color: #000;
}

.smileys {
  font-size: 54px;
}

.smileys label {
  min-width: 100px !important;
}

.smileys .very_unhappy input:checked + label {
  background-color: #b80c09;
  border-color: #000;
  color: #ffffff;
}

.smileys .unhappy input:checked + label {
  background-color: #ff8819;
  border-color: #000;
}

.smileys .yellow input:checked + label {
  background-color: #eee622;
  border-color: #000;
}

.smileys .light-green input:checked + label {
  background-color: #cbee22;
  border-color: #000;
}

.smileys .very_happy input:checked + label {
  background-color: #22ee80;
  border-color: #000;
}

.smileys .very_unhappy input:not(:checked) + label {
  color: #b80c09;
}

.smileys .unhappy input:not(:checked) + label {
  color: #ff8819;
}

.smileys .yellow input:not(:checked) + label {
  color: #bfba1b;
}

.smileys .light-green input:not(:checked) + label {
  color: #a4bf1b;
}

.smileys .very_happy input:not(:checked) + label {
  color: #1bbf67;
}

.rotate-90 {
  transform: rotate(-90deg);
}

.feedback-page .thumbs label {
  font-size: 40px;
  padding: 13px;
}

.feedback-page input[type="submit"] {
  width: 100.2%;
  padding: 15px 7px;
  border-radius: 15px;
  border: 7px solid var(--page-border-color, #20c7d4);
  min-width: 200px;
}

.feedback-page input[type="submit"]:hover {
  cursor: pointer;
  background-color: var(--page-border-color, #20c7d4);
  color: #000;
  font-weight: 600;
  border: 7px solid #000;
}

.feedback-page input {
  font-size: 22px;
  padding-left: 6px;
  border: 1px solid #aaa;
  border-radius: 5px;
}

.feedback-page .question {
  padding: 10px 10px 20px;
}

.feedback-page .question:last-child {
  padding: 10px 10px 0px;
}


.feedback-page .question-title {
  font-weight: 600;
}

.feedback-page .feedback {
  margin-bottom: 300px;
}

.anonymous-text {
  background-color: #83cc5585;
  border: 2px solid #47ad07;

  border-radius: 4px;
  margin-bottom: 26px;
  padding: 10px;
}

.top-margin {
  margin-top: 5px;
}

.bottom-margin {
  margin-bottom: 10px;
}

/* -- information page -- */

.confirm-form {
  margin: 30px auto 0;
  border-radius: 15px;
  padding: 20px;
  max-width: 800px;


  vertical-align: top;
  min-width: 200px;
  background-color: #ffffffde;
}

.confirm-form .message {
  margin-bottom: 70px;
}

.confirm-form input:not([type="submit"]) {
  width: 40px;
  height: 30px;
  font-size: 22px;
  padding-left: 6px;
  border: 1px solid #aaa;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-form label {
  vertical-align: super;
  cursor: pointer;
  margin-left: 20px;
}

.confirm-form input[type="checkbox"] {
  margin-bottom: 20px;
}

.confirm-form input[type="submit"] {
  padding: 15px 7px;
  background-color: #ffffffde;
  border: 7px solid var(--page-border-color, #20c7d4);
  border-radius: 30px;
  min-width: 200px;
  font-size: 22px;
}

.confirm-form input[type="submit"]:hover {
  cursor: pointer;
  background-color: var(--page-border-color, #20c7d4);
  color: #000;
  font-weight: 600;
  border: 7px solid #000;
}

/* -- popup box -- */

.popup-container {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  height: 100%;
  border-radius: 10px;
}

.popup-content {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 30px 60px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 60px 0px #000000bd;
  min-width: 40vw;
}

.popup-small {
  color: #b80c09;
  font-size: 12px;
}

.popup-padding {
  margin: 40px;
}

.popup-button {
  height: 50px;
  width: 120px;
  font-size: 20px;
  margin: 30px 10px 0px;
  border-radius: 5px;

  border-radius: 15px;
  padding: 20px;
  vertical-align: top;
  min-width: 200px;
  background-color: #ffffffde;
}

.popup-button:hover {
  cursor: pointer;
  background-color: var(--page-border-color, #20c7d4);
  color: #000;
  font-weight: 600;
  border: 2px solid #000;
}


/* -- autocomplete -- */

.autocomplete-container {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  height: 100%;
  border-radius: 10px;
}

.autocomplete-content {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 30px 60px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 60px 0px #000000bd;
  min-width: 30vw;
}

.autocomplete-button {
  height: 50px;
  width: 120px;
  font-size: 20px;
  margin: 30px 10px 0px;
  border-radius: 5px;
}

.autocomplete-button:hover {
  cursor: pointer;
  background-color: var(--page-border-color, #20c7d4);
  color: #000;
  font-weight: 600;
  border: 2px solid #000;
}

/* -- planned-events -- */

.planned-events-container {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  height: 100%;
  border-radius: 10px;
}

.planned-events-content {
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 30px 60px;
  position: absolute;
  box-shadow: 0px 0px 60px 0px #000000bd;
  transform: translate(-50%, 0%);
  min-width: 30vw;
}

.planned-events-list {
  margin-bottom: 15px;
}

.planned-events-arriving input{
  width: 10%;
}

.planned-events-arriving label{
  display: inline;
}

.planned-events-button {
  height: 50px;
  width: 120px;
  font-size: 20px;
  margin: 15px 10px 0px;
  border-radius: 5px;
}

.planned-events-button:hover {
  cursor: pointer;
  background-color: var(--page-border-color, #20c7d4);
  color: #000;
  font-weight: 600;
  border: 2px solid #000;
}

/* -- visiting who page -- */

.visiting-who-page input[type="checkbox"] {
  width: 0;
  opacity: 0;
  padding: 0;
  margin: 0;
}

.visiting-who-page .widgets {
  grid-gap: 0px;
}

.visiting-who-page .widgets label {
  border-radius: 15px;
  padding: 20px;
  vertical-align: top;
  display: inline-block;
  cursor: pointer;
  min-width: 50px;
  margin: 10px;
  background-color: #ffffffde;
  text-decoration: none;
  border: 5px solid #ddd
}

.visiting-who-page input:checked + label {
  background-color: var(--page-border-color, #20c7d4);
  border-color: #000;
}

.visiting-who-page input[value="Not visiting anyone"] {
  border-radius: 15px;
  padding: 20px;
  min-width: 50px;
  margin: 10px;
  border: 5px solid #ddd;
}

.typed-name-container {
  width: auto !important;
}

.typed-name {
  width: 80%;
  height: 30px;
  font-size: 22px;
  padding-left: 6px;
  border: 1px solid #555;
  border-radius: 5px;
}

.typed-name-button {
  font-size: 22px;
}

.typed-name-add-button {
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.padding-bottom {
  padding-bottom: 20px;
}

/* -- covid pass page -- */

.center {
  margin: auto;
}

.camera-container {
  margin: auto;
  margin-top: 20px;
  text-align: center;
}

.camera-container section section {

  border-radius: 30px;
}

.camera-container section div,
.camera-container section video {
  border-radius: 25px;
}

@media only screen and (max-width: 650px) {

  #root {
    height: 100%;
  }

  .main-page {
    justify-items: normal;
    padding: 0 10px;
  }

  .widgets {
    display: block;
  }

  .show-on-mobile {
    display: block !important;
  }

  .hide-on-mobile {
    display: none;
  }

  .scroll-message {
    display: none;
  }

  .main-page .widget {
    display: block;
    padding: 40px;
    margin: 20px auto;
  }

  .widget {
    min-width: 0px;
  }

  .main-page .qr-code {
    margin-bottom: 20px;
  }

  .desktop-stars {
    display: none;
  }

  .mobile-stars {
    display: block;
    margin-top: 5px;
  }

  .smileys label {
    width: 30%;
  }

  .logo-container {
    max-height: 80px;
    max-width: 100px;
  }

  .logo {
    height: 60px;
  }

  a:has(> .logo-container) {
    min-height: 60px;
    line-height: normal;
  }
}

@media only screen and (max-width: 530px) {
  .feedback-page .radio label.longer {
    width: 110px;
  }

  .smileys label {
    width: 20%;
  }

  .typed-name {
    width: 70%;
  }
}

@media only screen and (max-width: 425px) {

  /* -- setup pages -- */

  .main-logo {
    width: 90%;
    margin: 0 5%;
  }

  .login-box {
    width:100%;
    height: 100%;
  }

  .login-box .errors {
    border-radius: 15px;
    border-width: 3px;
    width: 94%;
  }

  /* -- other pages -- */

  .logo-container {
    max-height: 80px;
    max-width: 80px;
  }

  .logo {
    height: 60px;
  }

  .errors {
    border-radius: 0px;
    border-width: 3px 0px 3px 0px;
  }

  .errors ul {
    padding: 0;
    list-style: none;
    font-weight: bold;
  }

  #root {
    border: none;
    margin: 0px;
  }

  .main-page {
    padding: 0px;
  }

  .header {
    border-width: 0px 0px 3px 0px;
    border-radius: 0px;
    margin-top: 0px;
    text-align: center;
  }

  .main-page .content {
    margin-bottom: 0px;
  }

  .main-page .background {
    border-radius: 0px;
    border-width: 5px 0px 5px 0px;
  }

  .feedback-page .feedback-type {
    border-radius: 0px;
    border-width: 5px 0px 5px 0px;
    padding: 20px 10px;
  }

  .confirm-form {
    border-radius: 0px;
    border-width: 5px 0px 5px 0px;
    padding: 20px 15px;
  }

  .confirm-form label {
    line-height: 0px;
  }

  .confirm-form input[type="submit"] {
    margin-top: 20px;
    text-decoration: underline;
    border-radius: 15px;
    height: 75px;
  }

  .widget.form {
    border-radius: 0px;
    border-width: 5px 0px 5px 0px;
    padding: 50px 30px;
    background-color: #ffffffde;
    position: relative;
  }

  .form input[type="submit"] {
    background-color: #ffffffde;

    text-decoration: underline;
    border-radius: 15px;
    height: 75px;
  }

  .form label {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }

  .form input {
    margin-bottom: 20px;
    height: 35px;
  }

  .form .text {
    text-align: left;
    margin-bottom: 3px;
  }

  .form .message {
    margin-bottom: 45px;
  }

  .feedback-page .radio label.longer {
    width: 110px;
  }

  .feedback-page input[type="submit"] {
    -webkit-appearance: none;
    text-decoration: underline;
    border-radius: 15px;
    height: 75px;
    background-color: #ffffffde;
    color: #000;

  }
}

@media only screen and (max-width: 375px) {
  .feedback-page .radio label.longer {
    width: 90px;
  }
}

@media only screen and (max-width: 320px) {
  .feedback-page .radio label.longer {
    width: 60px;
  }

  .feedback-page .radio .mobile-stars label {
    padding: 7px;
    font-size: 34px;
  }

  .mobile-stars {
    margin-top: 10px;
  }
}
